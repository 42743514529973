<template>
	<v-container class="mt-8 ml-2 transparent elevation-0" fluid>
    <v-row class="pa-4">
      <v-col cols="12" sm="6" md="6" lg="4" class="pt-0 pr-0">
        <v-card class="transparent elevation-1">

          <!-- Seleccionaar el usuario -->
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
            tile
          >
          	<v-btn color="success" @click="getChats()" tile text dark>Actualizar</v-btn>
          	<v-spacer></v-spacer>
          	Chats: {{ filterAccount }}
          </v-toolbar>

          <!-- SECCIÖN DE CHAT -->
          <v-card-text class="blue-grey lighten-5">
            <v-row>
              <v-col cols="10">
                <v-text-field
                  :readonly="filterChats ? false : true"
                  name="name"
                  label="Buscar chat"
                  v-model="buscarUsuario"
                  hide-details
                  dense
                  :autofocus="buscarUsuarioActive"
                  filled
                  single-line
                  prepend-inner-icon="mdi-magnify"
                  :append-outer-icon="notSeen ? 'mdi-filter' : 'mdi-filter-off'"
                  @click:append-outer="notSeen = !notSeen"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn icon @click="notResponse = !notResponse" class="pt-1">
                  <v-icon>{{ notResponse ? 'mdi-message-bulleted' : 'mdi-message-bulleted-off' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- SECCIÓN DE FILTRO PARA WHATSAPP -->
          <v-card-text class="pa-0">
            <div v-if="notSeen">
            	<v-chip
  				      class="ma-2"
  				      color="pink"
  				      label
  				      text-color="white"
  				    >
  				      <v-icon left>
  				        mdi-account
  				      </v-icon>
  				      {{ filterChats.length }}
  				    </v-chip>

  				    <v-chip
  				      class="ma-2"
  				      color="cyan"
  				      label
  				      text-color="white"
  				    >
  				      <v-icon left>
  				        mdi-message-bulleted
  				      </v-icon>
  				      {{ filterChats.map(item => item.cant_mensajes ).reduce((prev, curr) => prev + curr, 0) }}
  				    </v-chip>
            </div>


            <!-- SECCI*ON DE LOS CONTACTOS -->
            <div
              :style="`height: 610px; overflow: auto;`" 
              id="preguntas"
            >
              <v-list dense>
                <template v-for="(chatW, i) in filterChats" :kei="i">
                  <v-divider inset></v-divider>

                  <v-list-item
                    @click="verChat( chatW )"
                    :class="chatW == chat ? 'grey lighten-3' : '' "
                  >
                    <!-- AVATAR -->
                    <v-list-item-avatar>
                      <v-icon :color="getColorIcon( chatW )">mdi-account-circle</v-icon>
                    </v-list-item-avatar>

                    <!-- contenido del contacto -->
                  	<v-list-item-content>

                      <!-- Nombre del contacto -->
  					          <v-list-item-title v-text="getNombre(chatW)"></v-list-item-title>

  					          <v-list-item-subtitle >
                        <!-- VISTO -->
                        <v-icon 
                          small 
                          class="mr-1" 
                          v-if="chatW.fueEnviadoPorMi" 
                          :color="chatW.fueLeido ? 'grey' : 'blue'"
                        >
                          mdi-check-all
                        </v-icon>

                        <!-- MOSTRAR ULTIMO MENSAJE -->
                        <span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              > {{ chatW.ultimoMensaje }}</span>
                            </template>
                            <span>{{ chatW.ultimoMensaje }}</span>
                          </v-tooltip>
                        </span>   

                      </v-list-item-subtitle>

  					        </v-list-item-content>


  					        <v-list-item-icon>
                      <!-- HORA DEL CHAT -->
                      <v-list-item-action-text >{{  chatW.horaUltimoMensaje.replace('T', ' ').substring(0,19) }}</v-list-item-action-text>

                      <!-- Marcador para saber cuando mensaje no leídos son -->
  					          <!-- <v-chip v-if="chatW.no_leidos.length" color="green" small dark class="pa-2"> -->
  					            <!-- {{ chatW.no_leidos.length }} -->
  					          <!-- </v-chip> -->
  					        </v-list-item-icon>

                    <!-- Marcador para saber si esta pineado o nel -->
                    <!-- <v-btn color="grey" absolute class="mt-5" icon right x-small v-if="chatW.pinned"><v-icon>mdi-pin</v-icon></v-btn> -->

                  </v-list-item>
                </template>
              </v-list>
            </div>
          </v-card-text>

        </v-card>
      </v-col>

      <!-- SECCIÓN DEL CHAT -->
      <v-col cols="12" sm="6" md="6" lg="8" class="pt-0 pl-0">

        <!-- MOSTRAR COMO QUIERA UN TOOLBAR SI NO HAY UN CHAT -->
        <v-card class="elevation-1" v-if="!chat">
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
            tile
          >
          </v-toolbar>
        </v-card>

        <!-- MOSTRAR SOLO SI HAY UN CHAT -->
        <v-card class="elevation-1" v-if="chat">
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
            tile
          >
            {{ chat.numero }}

            <v-spacer></v-spacer>
            <!-- MENÚ DE LOS 3 PUNTOS PARA EL DESPLIEGUE DE OPCIONES -->
            <v-menu offset-y >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-3"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense nav >
                <v-list-item link>
                  <v-list-item-title @click="parametrosAsignarVendedora.estatus = true">Asignar o reasignar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>


          <section class="chat">
            <div 
              class="messages-chat" 
              :style="`max-height: 680px; overflow: auto;`" 
              id="mensajesChat"
            >
              <div id="scroll">
                <MensajesTwilio  :mensajes="mensajes" :alumno="alumno" :wha="wha" :chat="chat" :verTodosLosMensajes="verTodosLosMensajes" :asignacion="asignacion" @getChats="getChats"/>
              </div>
            </div>
          </section>
        </v-card>
      </v-col>

      <!-- Dialog de carga -->
      <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
      <carga                v-if="cargar"/>

    </v-row>
    
	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  import { io } from "socket.io-client";

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import MensajesTwilio           from '@/components/whatsapp/MensajesTwilio.vue';
  import AsignarVendedora      from '@/components/whatsapp/AsignarVendedora.vue';

  import validarErrores        from '@/mixins/validarErrores'


  export default {
  	components:{
      Alerta,
      carga,
      MensajesTwilio,
      AsignarVendedora
    },

    mixins: [ validarErrores ],


    data: () => ({
      buscarPregunta:'',
      buscarActive:false,

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      usuarios:[],

      usuario: null,
      buscarUsuario:'',
      buscarUsuarioActive:true,
      alumno:null,
      url: 'https://escuelakpi.club/kpi/whatsapp-imagenes/',
      chats:[],

      notSeen:false,
      searchMensaje:'',
      mensajes:[],
      chat:null,

      internos: ['5218115865223',  '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223','5218121058094','5218180761486','5218111945886', '5218120790973'],

      wha: null,
      notResponse:false,

      verTodosLosMensajes: 0,
      message: '',

      parametrosAsignarVendedora:{
        estatus: false
      },
      asignacion: null,

      usuariosAcceso: [334,1,526,361, 568, 718, 859, 255, 966, 568, 7, 161, 3 ]
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      filterChats(){
    		let chats = this.chats 

    		if( this.notSeen ){ chats = chats.filter(el => { return el.no_leidos.length > 0 }) }


        if( this.notResponse ){ chats = chats.filter( el => { return el.ultimo_mensaje.length && !el.ultimo_mensaje[0].fromMe }) }

        // Verificamos que haya texto en buscar usuario
        if( this.buscarUsuario  ){
        	// Convertir el objeto a un array de [clave, valor]
					const conversacionesArray = Object.entries(chats);

					// Filtrar las conversaciones por número
					chats = conversacionesArray.filter(([numero, datos]) => numero.match( this.buscarUsuario ));
					chats = Object.fromEntries(chats);
        }

        return chats
      },

      filterAccount( ){


      	// Convertir el objeto a un array de [clave, valor]
				const conversacionesArray = Object.entries(this.chats);

				// Filtrar las conversaciones por número
				return conversacionesArray.length

      }
    },

    watch:{
    },

    async created () {
      await this.getChats()
    },

    methods: {
      getChats ( ) {
        
        this.cargar = true
        this.chats  = []

        return this.$http.post('datos.chats').then(response=>{

          this.chats      = response.data.conversaciones
          this.mensajes   = response.data.messages

          this.cargar  = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      avatar ( nombre ) {
        return "https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=" + nombre
      },

      avatarGrupo( nombre ) {
        return "https://ui-avatars.com/api/?background=F4511E&color=fff&rounded=true&format=svg&name=" + nombre
      },

      avatarInterno( nombre){
        return "https://ui-avatars.com/api/?background=D50000&color=fff&rounded=true&format=svg&name=" + nombre
      },

      verChat( chat ){
        this.chat = chat

        // const payload = {
        //   whaid      : chat.id.user +'@'+ chat.id.server,
        //   id_whatsapp: chat.id.user,
        //   escuela    : this.usuario.plantel.match('FAST') ? 2 : 1
        // }

        // this.cargar   = true
        // this.mensajes = []

        // const url = this.wha.url_servidor + 'whatsapp.chat.telefono'

        
        // return this.$http.post(url, payload ).then(response=>{
        //   console.log( response.data )
        //   this.mensajes   = response.data.mensajesWha
        //   this.asignacion = response.data.getAsignacion

        //   setTimeout(function(){document.getElementById('mensajesChat').scrollTop=document.getElementById('mensajesChat').scrollHeight},1000);
        //   this.cargar   = false
        // }).catch( error =>{
        //   this.validarError( error.response.data.message )
        // }).finally( () => { this.cargar = false })
      },

      parseUnix( value ){
        var date = new Date( value * 1000);

        var date = (new Date(new Date( value * 1000) - (new Date()).getTimezoneOffset() * 60000))

        return value ? date.toISOString().substr(0, 10) + ' ' + date.toISOString().substr(11, 5) : 'Sin fecha'
      },

      getNombre( texto ){
        // return this.internos.includes(texto.id.user ) ? 'Interno ' + texto.name : texto.name 
      	return texto.numero.replace('whatsapp:+','wpp: +')
      },

      getMensaje( texto ){
        // if( texto.ultimo_mensaje.length ){
        //   if( texto.ultimo_mensaje[0].type == 'chat')
        //     return texto.ultimo_mensaje[0].body
        //   else 
        //     return texto.ultimo_mensaje[0].type
        // }

        return ''
      },

      getColorIcon( chat ){
      	return 'primary'
      },

      sendMessage( value ){
        console.log( this.message )
        this.message = ''
      }

    },
  }
</script>

<style  scoped>

  #mensajesChat{
    background-image: url("/fondo_whatsapp.jpg");
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size:contain;
  }

  #preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  /**********************************/

  #mensajesChat::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #mensajesChat::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar-button:increment,#mensajesChat::-webkit-scrollbar-button{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar:horizontal{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .container {
    padding:0;
    background-color: #FFF; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 750px;
    margin-top: 25px;
  }

  /* ===== MENU ===== */
  .menu {
    float: left;
    height: 700px;;
    width: 70px;
    background: #4768b5;
    background: -webkit-linear-gradient(#4768b5, #35488e);
    background: -o-linear-gradient(#4768b5, #35488e);
    background: -moz-linear-gradient(#4768b5, #35488e);
    background: linear-gradient(#4768b5, #35488e);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19);
  }

  .menu .items {
    list-style:none;
    margin: auto;
    padding: 0;
  }

  .menu .items .item {
    height: 70px;
    border-bottom: 1px solid #6780cc;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #9fb5ef;
    font-size: 17pt;
  }

  .menu .items .item-active {
    background-color:#5172c3;
    color: #FFF;
  }

  .menu .items .item:hover {
    cursor: pointer;
    background-color: #4f6ebd;
    color: #cfe5ff;
  }

  /* === CONVERSATIONS === */

  .discussions {
    width: 100%;
    height: 702px;
    box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
    overflow: hidden;
    display: inline-block;
  }

  .discussions .discussion {
    width: 100%;
    height: 90px;
    background-color: #FAFAFA;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search {
    display:flex;
    align-items: center;
    justify-content: center;
    color: #E0E0E0;
  }

  .discussions .search .searchbar {
    height: 40px;
    background-color: #FFF;
    width: 70%;
    padding: 0 20px;
    border-radius: 50px;
    border: 1px solid #EEEEEE;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search .searchbar input {
    margin-left: 15px;
    height:38px;
    width:100%;
    border:none;
    font-family: 'Montserrat', sans-serif;;
  }

  .discussions .search .searchbar *::-webkit-input-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *::-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-ms-input-placeholder {
      color: #E0E0E0;
  }

  .discussions .message-active {
    height: 90px;
    background-color: #FFF;
    border-right: solid 5px #E91E63;
  }

  .discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
  }

  .desc-contact {
    height: 43px;
    width:50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .discussions .discussion .name {
    margin: 0 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 11pt;
    color:#515151;
  }

  .discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 9pt;
    color:#515151;
  }

  .timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;
  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .response .text {
    background-color: #E91E63 !important;
    color: white;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;
    
  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }

</style>
